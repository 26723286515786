@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("../src/fonts/ABCWhyteInktrapSemi-Mono-Regular-Trial.otf");
}

@font-face {
  font-family: "ABC Prophet";
  src: url("../src/fonts/ABCProphetVariable-Trial.ttf");
  font-weight: thin normal medium bold;
}

@font-face {
  font-family: "ABC Ginto";
  src: url("../src/fonts/ABCGintoNormalVariable-Trial.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "ABC Ginto Nord";
  src: url("../src/fonts/ABCGintoNordVariable-Trial.ttf");
  font-weight: thin normal medium bold;
}

@font-face {
  font-family: "ABC Whyte Mono";
  src: url("../src/fonts/ABCWhyteMono-Regular-Trial.otf");
  font-weight: normal;
}

@font-face {
  font-family: "ABC Viafont";
  src: url("../src/fonts/ABCViafont-Regular-Trial.otf");
  font-weight: normal;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 53px;
  width: 15px;
  color: lightgray;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 410px);
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: none;
  position: relative;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #55555583;
}

.image-gallery-thumbnail:hover {
  outline: none;
  border: 4px solid #b6b6b69e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
